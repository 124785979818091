.pageNumbers {
  list-style: none;
  display: flex;
}

.pageNumbers li {
  padding: 10px;
  border: 1px solid white;
  cursor: pointer;
}

.pageNumbers li.active {
  background-color: white;
  color: black;
}

.pageNumbers li button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}
.pageNumbers li button:hover {
  background-color: white;
  color: black;
}
.pageNumbers li button:focus {
  outline: none;
}

.loadmore {
  padding: 1rem;
  background-color: transparent;

  color: white;
  font-size: 1.2rem;
  border: 1px solid white;
  cursor: pointer;
}
