.nplay-video-container :root {
  --videoColor: rgba(93, 136, 222, 0.9);
}
.nplay-video-container {
  /* direction: ltr !important; */
  /* border-radius: 16px; */
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  /* border: 1px solid #cccaca; */
  background-color: var(--videoColor);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media only screen and (max-width: 480px) {
  .nplay-video-container {
    margin-bottom: 16px;
  }
}
@media only screen and (min-width: 481px) and (max-width: 767px) {
  .videoPreview {
    height: 280px !important;
  }
}
@media only screen and (min-width: 768px) {
  .videoPreview {
    height: 320px;
  }
}
.nplay-video-container i {
  font-size: 18px;
  color: #ffff;
}
/* .nplay-video-progress:hover .seek-tooltip {
  display: block;
} */
.nplay-video-progress:hover .nplay-scroll,
.nplay-video-progress:hover .nplay-buffer,
.nplay-video-progress:hover .nplay-mask {
  height: 7px;
}
.nplay-video-container button:focus {
  outline: none;
}
.nplay-video-container video {
  height: 100%;
  width: 100%;
  left: 0;
  /* top: 0; */
  /* border: 1px solid #efefef; */
}

.nplay-video-controls {
  right: 0;
  left: 0;
  padding: 10px 5px 0 5px;
  position: absolute;
  bottom: 0;
  transition: all 0.2s ease;
}
.nplay-video-controls_bg {
  right: 0;
  left: 0;
  position: absolute;
  bottom: 0;
  height: 30%;
  border-radius: 16px;
  background: linear-gradient(
    180deg,
    rgb(0 0 0 / 1%) 12.2%,
    rgb(0 0 0 / 39%) 77.49%,
    rgb(0 0 0 / 76%) 100%
  );
  transition: 0.4s;
  opacity: 0;
}
.nply-forward i {
  font-size: 15px;
}
.nplay-video-progress {
  margin: 0 15px 6px 15px;
  padding: 5px 0;
}
.nplay-video-progress:hover .nplay-bar {
  display: block;
  z-index: 1;
}
/* .nplay-fullcsreen-desktop {
  width: 100%;
  height: 100%;
} */
.nplay-video-controls.hide {
  opacity: 0;
  pointer-events: none;
}
.nplay-scroll {
  width: 100%;
  height: 5px;
  background: rgba(160, 160, 160, 0.7);
  position: relative;
  cursor: pointer;
}
.nplay-bar {
  width: 16px;
  height: 16px;
  background: #1e88e5;
  position: absolute;
  top: -5px;
  /* left: 0; */
  cursor: pointer;
  border-radius: 50px;
  display: none;
}
.nplay-mask {
  position: absolute;
  left: 0;
  top: 0;
  background: #1e88e5;
  width: 0;
  height: 5px;
  border-radius: 5px 0 0 5px;
}
.nplay-buffer {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(218, 218, 218, 0.6);
  width: 0;
  height: 5px;
  border-radius: 5px;
}
.seek-tooltip {
  position: absolute;
  top: -26px;
  margin-left: -20px;
  font-size: 12px;
  padding: 5px 11px;
  content: attr(data-title);
  background: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: rgba(73, 80, 87, 1);
}
.nplay-bottom-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px 13px 14px;
}
.nplay-left-controls {
  display: flex;
  align-items: center;
  color: #fff;
}
.nplay-volume-controls {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.progress-bar.nplay {
  background: #aeaeae;
  height: 8px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.nply-Ohidden {
  opacity: 0;
}
.nplay-video-container button:not(.nplay-mobile-voice-mute) {
  cursor: pointer;
  position: relative;
  margin-right: 7px;
  font-size: 17px;
  padding: 3px;
  border: none;
  outline: none;
  background-color: transparent;
}
.nplay-video-container button * {
  pointer-events: none;
}
.nplay-video-container button::before {
  /* content: attr(data-title);
  position: absolute;
  display: none;
  right: -8px;
  bottom: 34px;
  color: rgba(73, 80, 87, 1);
  font-weight: 400;
  padding: 5px 7px;
  word-break: keep-all;
  white-space: pre;
  background: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-size: 13.22px;
  direction: rtl; */
  display: none !important;
}
.nplay-video-container button:hover::before {
  /* display: inline-block; */
  display: none !important;
}
.nplay-fullscreen-button {
  margin-right: 0;
}
.nplay-playback-animation {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -40px;
  margin-top: -40px;
  width: 80px;
  height: 80px;
  border-radius: 80px;
  background-color: rgba(36, 131, 213, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  cursor: pointer;
}
.nplay-playback-animation i {
  font-size: 25px;
  color: #ffff;
  margin: 2px 0 0 4px;
}
.nplay-video-container:hover {
  cursor: pointer;
}
.nplay-video-controls:hover {
  cursor: default;
}
.nplay-video-container:hover .nplay-playback-animation {
  border: 2px solid rgba(0, 0, 0, 0.45);
  background-color: rgb(24 120 203 / 90%);
}
.nplay-video-container:hover .nplay-playback-tooltip {
  display: block;
  cursor: pointer;
}
.hidden {
  display: none;
}
.nplay-qualities_btn {
  color: #fff;
  font-size: 15px;
}
.nplay-qualities {
  display: flex;
  position: absolute;
  bottom: 52px;
  right: 5px;
  flex-direction: column;
  list-style: none !important;
  background: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 130px;
  padding: 0;
}
span.nplay-qualities-titile {
  color: #495057;
  font-size: 14.22px;
  padding: 8px 16px;
  border-bottom: 1px solid rgba(218, 218, 218, 0.6);
  font-weight: bold;
}
.nplay-rate-list {
  width: 140px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 52px;
  right: -50px;
  background: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.nplay-rate-list span:first-child {
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #495057;
  padding: 6px 16px;
  border-bottom: 1px solid rgba(218, 218, 218, 0.6);
  font-weight: bold;
}
.nplay-rate-list button,
.nplay-qualities button {
  min-width: 120px;
  min-height: 35px;
  font-size: 14.22px !important;
  line-height: 24px;
  color: #5a5a5a;
  margin-right: 0 !important;
  text-align: right;
  padding: 0 17px !important;
}
.nplay-rate-list button:hover,
.nplay-qualities button:hover {
  background: #2483d5;
  color: #ffffff;
}
.nplay-tick-mark {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  float: left;
  margin-right: 1.5rem;
}
.nplay-rate-list span {
  font-size: 14px;
}
.nplay-tick-mark::before {
  position: absolute;
  left: 0;
  top: 50%;
  height: 50%;
  width: 3px;
  background-color: #5a5a5a;
  content: "";
  transform: translateX(10px) rotate(-45deg);
  transform-origin: left bottom;
}
.nplay-tick-mark::after {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 97%;
  background-color: #5a5a5a;
  content: "";
  transform: translateX(10px) rotate(-45deg);
  transform-origin: left bottom;
}
.nplay-rateSpeed {
  color: #eee;
  font-size: 16px;
  /* vertical-align: -2px; */
}
.nplay-qualities button:hover::before,
.nplay-rate-list button:hover::before,
.nplay-qualities_btn:hover::before {
  display: none;
}
.nplay-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -40px;
  margin-top: -40px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.nplay-volume-wrap {
  width: 60px;
  transform: translate(-2%, -3%);
}
.nplay-volume {
  width: 100%;
  cursor: pointer;
  opacity: 0;
}
.nplay-volume::-ms-tooltip {
  display: none;
}
.nplay-volume-track {
  width: 100%;
  height: 4px;
  background: rgba(217, 217, 217, 0.4);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  border-radius: 15px;
}
.nplay-volume-track-inner {
  width: 0;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  border-radius: 15px;
  float: left;
}
.nplay-volume-thumb {
  width: 10px;
  height: 10px;
  background: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
  pointer-events: none;
}
/* .mobile-fullscreen {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
} */
/* .mobile-fullscreen-time1 {
  position: absolute;
  top: 28px;
  left: 10px;
}
.mobile-fullscreen-time2 {
  top: 28px;
  position: absolute;
  right: 10px;
} */
.nplay-playback-tooltip {
  position: absolute;
  top: -15px;
  left: -17px;
  color: rgba(73, 80, 87, 1);
  font-weight: 400;
  padding: 5px 7px;
  word-break: keep-all;
  white-space: pre;
  background: #ffffff;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  border-radius: 4px;
  font-size: 13.22px;
  display: none;
}

.nplay-next-large span {
  font-size: 12px !important;
}
.nplay-next-large p {
  font-size: 12px !important;
  padding: 15px 10px !important;
  height: 48px !important;
}
.nplay-mobile-plaback-icons {
  position: absolute;
  top: 35%;
  z-index: 1;
}
.nplay-mobile-plaback-icons button {
  margin: 0 22px;
}
.nplay-mobile-voice-mute {
  position: absolute !important;
  top: 1rem;
  right: 13px;
  background: rgba(90, 90, 90, 0.8);
  border-radius: 4px;
  padding: 16px;
  display: none;
  margin-right: 7px;
  font-size: 17px;
  border: none;
  outline: none;
  height: 50px;
  z-index: 1;
}
.nplay-mobile-voice-mute svg {
  vertical-align: top;
}
.noHover:before,
.nhm:before {
  display: none !important;
}
@media (max-width: 992px) {
  .nplay-bar {
    display: block;
    z-index: 1;
  }
}
@media (max-width: 735px) {
  .nplay-qualities {
    right: 21px;
  }
  /* button.nply-forward.nply-jumptb,
  button.nply-forward.nply-jumptf,*/
  .nplay-volume-controls {
    display: none;
  }
  .nplay-mobile-voice-mute {
    display: block;
  }
  .nplay-video-container video {
    width: 100%;
    max-height: 100%;

    margin: 0 auto;
    /* top: 46%; */
    border: none;
  }
  .nplay-video-container button:hover::before {
    display: none;
  }
  .nplay-loader {
    width: 70px;
    height: 70px;
    top: 52%;
    left: 53%;
  }
  .nplay-rate-list {
    bottom: 46px;
    right: 0px;
    z-index: 1;
  }
  .nplay-time {
    margin-left: 16px;
    margin-top: 8px;
  }
  .nplay-video-progress {
    margin: 0 5px 6px 5px;
  }
}
@media (min-width: 735px) {
  .nplay-mobile-plaback-icons {
    display: none;
  }
}
@media (max-width: 430px) {
  .nplay-mobile-voice-mute svg {
    height: 14px;
  }
  .nplay-mobile-voice-mute {
    height: 40px;
    padding: 11px;
  }
  .nplay-playback-animation {
    height: 65px;
    width: 65px;
  }
}
@media (max-width: 360px) {
  .nplay-mobile-voice-mute svg {
    height: 12px;
  }
  .nplay-mobile-voice-mute {
    height: 35px;
    /* padding: 10px 10px 0px 9px; */
  }
  .nplay-playback-animation {
    height: 50px;
    width: 50px;
    left: 53%;
  }
}
@media (max-width: 338px) {
  .pip-button,
  .nplay-qualities_btn {
    display: none;
  }
  .nplay-bottom-controls {
    padding: 0 8px 3px 8px;
  }
  .nplay-video-progress {
    margin-bottom: 4px;
  }
  .nplay-playback-animation {
    left: 59%;
  }
}
video::cue {
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px !important;
  background-color: rgba(32, 35, 37, 0.85) !important;
  white-space: nowrap;
}
.firefox::cue {
  background: none;
  background-color: rgba(32, 35, 37, 0.85);
}
.noFireFox::cue {
  margin: auto;
}
@media (max-width: 600px) {
  .noFireFox::cue {
    white-space: pre-wrap !important;
    font-size: 8px;
    background-color: rgba(32, 35, 37, 0.85);
  }
}
.subtitleOn:after {
  content: "";
  display: block;
  margin: auto;
  height: 3px;
  margin-top: 5px;
  width: 100%;
  animation: animateSubtitle 0.5s forwards;
}
.subtitleOff:after {
  content: "";
  display: block;
  margin: auto;
  height: 3px;
  margin-top: 5px;
  width: 100%;
  background-color: transparent;
}
video::-webkit-media-text-track-display {
  white-space: pre-wrap;
  width: 80% !important;
  padding: 2px;
  border-radius: 16px;
  margin-bottom: 4px;
  display: flex;
  margin-left: 10%;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to bottom, rgba(32, 35, 37, 0.85), rgba(32, 35, 37, 0.85));
}
@keyframes animateSubtitle {
  0% {
    width: 0;
    background-color: transparent;
  }
  100% {
    width: 100%;
    background-color: #1e88e5;
  }
}

.cart_video {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  /* background: linear-gradient(
    180deg,
    rgb(0 0 0 / 1%) 7.2%,
    rgb(0 0 0 / 25%) 35.2%,
    rgb(0 0 0 / 50%) 77.49%,
    rgb(0 0 0 / 75%) 100%
  ); */
}
