.iconContainer {
  transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out; /* opera */
  -ms-transition: all 0.4s ease-out; /* IE 10 */
  -moz-transition: all 0.4s ease-out; /* Firefox */
  -webkit-transition: all 0.4s ease-out; /*safari and chrome */
  opacity: 0;
  bottom: -50px;
}

.newProduct:hover .iconContainer {
  transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out; /* opera */
  -ms-transition: all 0.4s ease-out; /* IE 10 */
  -moz-transition: all 0.4s ease-out; /* Firefox */
  -webkit-transition: all 0.4s ease-out; /*safari and chrome */
  opacity: 1;
  bottom: 5px;
}

/* .newProduct-container .swiper-button-prev ,.newProduct-container .swiper-button-next{
    color: black;
    width: 30px;
 
} */
.newProduct-container .swiper-button-next {
  right: 10px !important;
}
.newProduct-container .swiper-button-next::after {
  content: " " !important;
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 6px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.newProduct-container .swiper-button-prev::after {
  content: " " !important;
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 6px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.newProduct-container .swiper-button-prev {
  left: 10px !important;
}
