.custom-scroll ::-webkit-scrollbar {
  width: 8px;
}
.custom-scroll ::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 10px;
}
.custom-scroll ::-webkit-scrollbar-thumb {
  background: #d4d4d4;
  border-radius: 10px;
}
.custom-scroll ::-webkit-scrollbar-thumb:hover {
  background: #b9b9b9;
}
