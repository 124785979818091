.keen-slider-container .dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
}

.keen-slider-container .dot {
  border: none;
  width: 8px;
  height: 8px;
  background: #c4e1fd;
  border-radius: 50%;
  margin: 0 5px;
  padding: 4px;
  cursor: pointer;
}

.keen-slider-container .dot:focus {
  outline: none;
}

.keen-slider-container .dot.active {
  background: #008af6;
}
