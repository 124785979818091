.sticky_position {
  position: fixed;
  animation-name: slideDown;
  /* animation-delay:0.1s; */
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  transition: all 0.3s ease-in-out;
  z-index: 30;
  width: 100%;
  background-color: white;
}

/* .sticky_position > #dabdoob-header-layer1 {
  display: none;
} */

@keyframes slideDown {
  0% {
    top: -70px;
  }
  100% {
    top: 0px;
  }
}
