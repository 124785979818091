.form {
  /* width: 360px;
        position: fixed;
        top: 50%;
        left: 50%;
        background-color: white;
        padding: 2rem;
        transform: translate(-50%, -50%);
        box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, .2); */
}

.form__label {
  position: absolute;
  top: 18px;
  /* left: 0; */
  pointer-events: none;
  transition: all 0.3s ease;
}

.form__control:focus {
  border: 2px solid #008af6;
}

.form__control:focus ~ .form__label {
  top: 4px;
  font-size: 12px;
  color: #008af6;
}

.form__control:valid ~ .form__label {
  top: 4px;
  font-size: 12px;
  /* color:#008AF6; */
}

.form__control:not(:focus):valid ~ .form__label {
  font-size: 12px;
  color: #999999;
}

.form__control:not(:focus)[value=""] ~ .form__label {
  top: 18px !important;
  font-size: 14px;
  color: #999999;
}

/* .form__control:-webkit-autofill ~ .form__label{
    top: 4px;
    font-size: 12px;
  }
  .form__control:autofill ~ .form__label {
    top: 4px;
    font-size: 12px;
  } */

.err-label {
  color: #f60000 !important;
}

.err-input {
  border: 2px solid #f60000 !important;
}
.top-label {
  font-size: 12px !important;
  top: 4px !important;
}

.form__label-top {
  position: absolute;
  top: 4px;
  /* left: 0; */
  pointer-events: none;
  transition: all 0.3s ease;
}

.form__control:focus ~ .form__label-top {
  font-size: 12px;
  color: #008af6;
}

.form__control:valid ~ .form__label-top {
  top: 4px;
  font-size: 12px;
  /* color:#008AF6; */
}

.form__control:not(:focus):valid ~ .form__label-top {
  font-size: 12px;
  color: #999999;
}

.form__control:not(:focus)[value=""] ~ .form__label-top {
  top: 4px !important;
  color: #999999;
}
