@font-face {
  font-family: AdelleSans;
  src: url("../public/fonts/adelleSans/AdelleSansARA-Semibold.ttf");
  src: url("../public/fonts/adelleSans/AdelleSansARA-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: AdelleSans;
  src: url("../public/fonts/adelleSans/AdelleSansARA-Regular.ttf");
  src: url("../public/fonts/adelleSans/AdelleSansARA-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: AdelleSans;
  src: url("../public/fonts/adelleSans/AdelleSansARA-Extrabold.ttf");
  src: url("../public/fonts/adelleSans/AdelleSansARA-Extrabold.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
}
html {
  height: 100%;
  box-sizing: border-box;
  touch-action: manipulation;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 100%;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

html,
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-primary);
  color: var(--text-primary);
  overscroll-behavior-x: none;
  /* font-size: 16px; */
  font-weight: 400;
  position: relative;
  min-height: 100%;
  margin: 0;
  direction: ltr;
  scroll-behavior: smooth;
  cursor: default;
}

*,
*:before,
*:after {
  font-family: inherit;
}

svg {
  text-rendering: optimizeLegibility;
}
h1,
h2,
h3 {
  margin: 0;
}
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-decoration: none;
  transition: color 0.2s ease;
}
a:hover {
  color: var(--primary-color);
}
.clamp-3-line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.clamp-2-line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

@media (max-width: 992px) {
  .sticky-box {
    position: inherit !important;
    top: 0 !important;
  }
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .blurred-container-md {
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
  }
  .blurred-container-lg {
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
  }
  .blurred-container-xl {
    -webkit-backdrop-filter: blur(19px);
    backdrop-filter: blur(19px);
  }
  .blurred-container-sm {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  }
}

/* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .blurred-container-md {
    background-color: rgba(255, 255, 255, 0.9);
  }
  .blurred-container-lg {
    background-color: rgba(255, 255, 255, 0.93);
  }
  .blurred-container-xl {
    background-color: rgba(255, 255, 255, 0.96);
  }
  .blurred-container-sm {
    background-color: rgba(255, 255, 255, 0.82);
  }
}

.menu,
.menu li,
.menu ol,
.menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.menu {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
}
.menu:after {
  display: block;
  clear: both;
  content: "";
}
.menu .show > .megamenu,
.menu .show > ul {
  opacity: 1;
}
.menu li > a {
  display: block;
  padding: 0.8rem 1.8rem;
  transition: 0.2s ease-out;
  color: #777;
}
.menu li.active > a,
.menu li.show > a,
.menu li:hover > a {
  background: #f4f4f4;
}
.menu > li {
  position: relative;
  margin-right: 2.8rem;
}
.menu > li > a {
  padding: 1rem 0;
  font-size: 13px;
  font-weight: 400;
  color: #555;
}
.menu > li.active > a,
.menu > li.show > a,
.menu > li:hover > a {
  color: #008af6;
  background: transparent;
}
.menu > li > .sf-with-ul:before {
  content: "";
  position: absolute;
  z-index: 1000;
  left: 50%;
  bottom: 0;
  margin-left: -14px;
  border: 10px solid;
  border-color: transparent transparent #fff;
  opacity: 0;
}
.menu > li.show > .sf-with-ul:before {
  opacity: 1;
  visibility: visible;
}
.menu .megamenu {
  display: none;
  position: absolute;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.15);
  border: 1px solid #eee;
  border-top: 3px solid #c3512f;
  left: 15px;
  padding: 10px 20px;
  width: 580px;
}
.menu .megamenu.megamenu-3cols {
  width: 600px;
}
.menu .megamenu .row > div {
  padding-top: 1.5rem;
}
.menu .megamenu img {
  width: 300px;
  height: 100%;
  object-fit: cover;
}
.menu .megamenu .submenu {
  margin: 0;
  padding-top: 0;
  border-top: none;
  display: block;
  position: static;
  box-shadow: none;
  min-width: 0;
}
.menu .megamenu .submenu a {
  padding: 7px 8px 8px 0;
}
.menu .megamenu .submenu li:hover a {
  text-decoration: underline;
  background: transparent;
}
.menu .nolink {
  cursor: default;
  display: inline-block;
  padding-bottom: 11px;
  font-weight: 700;
  color: #333;
}
.menu ul {
  display: none;
  position: absolute;
  min-width: 200px;
  padding: 5px 0;
  border-top: 3px solid #c3512f;
  top: 100%;
  left: 0;
  z-index: 101;
  background-color: #fff;
  box-shadow: 0 29px 29px rgba(0, 0, 0, 0.1);
}
.menu ul.custom-scrollbar {
  max-height: 80vh;
  overflow-y: auto;
}
.menu ul ul {
  top: -5px;
  left: 100%;
}
.menu ul li {
  position: relative;
}
.menu ul li:hover ul {
  display: block;
}
.menu.sf-arrows .sf-with-ul + ul > li {
  position: relative;
}
.menu.sf-arrows .sf-with-ul:after {
  position: absolute;
  right: 1rem;
  content: "";
}
.menu.sf-arrows > li > .sf-with-ul:after {
  content: "";
  position: static;
  margin-left: 5px;
  font-weight: 400;
}
.main-nav .menu {
  text-transform: capitalize;
  font-size: 14px;
}
.main-nav .menu > li {
  margin-right: 2.5rem;
}
.main-nav .menu > li > a {
  font-size: 14px;
  font-weight: 700;
  padding: 20px 0;
}
.main-nav .menu > li:first-child > a {
  padding-left: 0;
}
.main-nav .menu > li:not(.float-right) + li.float-right,
.main-nav .menu > li:not(.float-right):last-child {
  margin-right: 0;
}
.main-nav .menu.sf-arrows ul {
  border-top: none;
}
.main-nav .menu > li > ul {
  left: -15px;
}
.main-nav .menu .megamenu {
  top: 100%;
  left: -15px;
  border-top: none;
}
.main-nav .menu .megamenu img {
  height: 100%;
  object-fit: cover;
}

.container {
  width: 100%;
  /* max-width: 1186px; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: auto;
}

.swiper-padding {
  padding: 8px 0 !important;
}
.left-to-right {
  direction: ltr !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-menu {
  background: linear-gradient(167.96deg, #008af6 0%, #002e52 100%, #002e52 100%);
}

.height-screen {
  height: 100dvh !important;
  min-height: -moz-available; /* WebKit-based browsers will ignore this. */
  min-height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  min-height: stretch;
  /* height: -moz-available !important; WebKit-based browsers will ignore this.
  height: -webkit-fill-available !important; Mozilla-based browsers will ignore this.
  height: stretch !important; */
  /* min-height: 100vh !important;
  min-height: -moz-available !important;
  min-height: -webkit-fill-available !important;
  min-height: stretch !important; */
  /* max-height: 100vh !important;
  max-height: -moz-available !important;
  max-height: -webkit-fill-available !important;
  max-height: stretch !important; */
}

* {
  -webkit-tap-highlight-color: transparent;
}
