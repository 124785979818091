.categories-container .swiper-button-prev {
  background-color: white;
  opacity: 0.8;
  width: 30px;
  height: 35px;
  left: 0px !important;
  top: 49% !important;
  border-radius: 0 8px 8px 0;
}
.categories-container .swiper-button-next {
  background-color: white;
  opacity: 0.8;
  width: 30px;
  height: 35px;
  right: 0px !important;
  top: 49% !important;
  border-radius: 8px 0px 0px 8px;
}
.categories-container .swiper-button-next::after {
  content: " " !important;
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 6px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.categories-container .swiper-button-prev::after {
  content: " " !important;
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 6px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
