@tailwind base;
@tailwind components;
@tailwind utilities;

@import "variables.css";
@import "base.css";
@import "chrome-bug.css";
@import "components/index.css";
@import "generated-content.css";
@import "scroll.css";
