.pre-product:hover .pre-product-item {
  height: 160px;
  /* transition: all 0.2s ease-in-out; */
  border-top: 4px solid black;
  padding: 12px;
}
.pre-product-item {
  /* transition: all 0.1s ease-in-out; */
}

.next-product:hover .next-product-item {
  height: 160px;
  /* transition: all 0.2s ease-in-out; */
  border-top: 4px solid black;
  padding: 12px;
}
.next-product-item {
  /* transition: all 0.1s ease-in-out; */
}

.product-image-container:hover .icon {
  display: block;
}
